<template>
  <div>
    <v-btn @click="displayCardForm()" color="compeatsecondary" icon>
      <v-icon>mdi-credit-card-plus</v-icon>
    </v-btn>
    <v-overlay :value="overlay">
      <v-row>
        <v-col cols="6" class="mx-auto">
          <v-card light flat class="mycardradius">
            <generalAlert
              class="mx-4"
              v-if="alert"
              :alert="alert"
              :alertType="alertType"
              :message="successResponse"
            />
            <v-form ref="paymentForm" v-model="paymentFormVaild">
              <v-card-text>
                <div class="px-4 py-4">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <h2>Card Details</h2>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="cardHolder"
                        :rules="fieldRules"
                        label="Card Holder"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="cardNumber"
                        :rules="fieldRules"
                        label="Card Number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" class="py-0">
                      <v-select
                        v-model="expMonth"
                        :items="this.months"
                        :rules="fieldRules"
                        label="Expiry Month"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="4" lg="4" class="py-0">
                      <v-select
                        v-model="expYear"
                        :items="this.years"
                        :rules="fieldRules"
                        label="Expiry Year"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" class="py-0">
                      <v-text-field
                        v-model="cvc"
                        label="CVV"
                        :rules="fieldRules"
                        :max="3"
                      ></v-text-field>
                    </v-col>
                    <v-row>
                      <v-col cols="6" class="text-center">
                        <v-btn
                          rounded
                          class="primary white--text"
                          @click="displayCardForm()"
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="text-center">
                        <v-btn
                          @click="submitPaymentDetails()"
                          :loading="loadingcard"
                          class="primary white--text"
                          rounded
                          :disabled="!paymentFormVaild"
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../../firebaseConfig";
import generalAlert from "../alerts/generalAlert.vue";

export default {
  props: [
    "stripeCustomerId",
    "searchForCustomerEmail",
    "agencyPaying",
    "getAcencyPaymentMethod",
  ],
  components: {
    generalAlert,
  },
  data: () => ({
    cardHolder: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    alert: false,
    alertType: "",
    successResponse: "",
    loadingcard: false,
    paymentFormVaild: false,
    fieldRules: [(v) => !!v || "Required"],
    currentYear: "",
    years: "",
    months: "",
    absolute: true,
    overlay: false,
  }),
  created() {
    this.currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 9 }, (_, i) => this.currentYear - 4 + i);
    this.months = Array.from({ length: 12 }, (_, i) => {
      const monthNumber = i + 1;
      return monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString();
    });
  },
  methods: {
    submitPaymentDetails() {
      this.loadingcard = true;

      const functions = getFunctions(firebaseApp, "europe-west2");
      const createPaymentMethod = httpsCallable(
        functions,
        "createPaymentMethod"
      );

      createPaymentMethod({
        stripeId: this.stripeCustomerId,
        cardHolder: this.cardHolder,
        cardNumber: this.cardNumber,
        expMonth: this.expMonth,
        expYear: this.expYear,
        cvc: this.cvc,
      })
        .then((result) => {
          console.log("data", result);
          this.alert = true;
          this.alertType = "success";
          this.successResponse = "Card Was Created Successfully.";
          setTimeout(() => {
            this.alert = false;
            this.overlay = false;
            this.$refs.paymentForm.reset();
            if (this.agencyPaying == true) {
              this.$emit("getAcencyPaymentMethod");
            } else {
              this.$emit("searchForCustomerEmail");
            }
            // this.$emit('searchForCustomerEmail')
          }, 5000);
          this.loadingcard = false;
        })
        .catch((err) => {
          console.log("submitPaymentDetails err", err);
          this.loadingcard = false;
          this.alert = true;
          this.alertType = "error";
          this.successResponse =
            "Opps something went wrong. Check card details";
          // setTimeout(() => {
          //     this.alert = false
          // }, 5000)
        });
    },
    validate() {
      this.$refs.paymentForm.validate();
    },
    displayCardForm() {
      this.overlay = !this.overlay;
    },
  },
};
</script>
