<template>
  <v-row>
    <v-col cols="12" class="my-2">
      <v-alert outlined v-model="alert" :type="alertType">
        {{ message }}
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["message", "alert", "alertType"],
  data: () => ({}),
};
</script>
