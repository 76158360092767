<template>
  <v-app>
    <v-app-bar height="130px" app color="white" dark>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="4">
            <v-img
              alt="Vuetify Logo"
              contain
              src="../src//assets/Compeat-logo.jpg"
              transition="scale-transition"
              height="120px"
            />
          </v-col>
          <v-col cols="6">
            <!-- <v-btn text dark class="primary--text"> HOME </v-btn>
            <v-btn text dark class="primary--text"> WHAT IS COMPEAT </v-btn>
            <v-btn text dark class="primary--text"> PACKAGES </v-btn>
            <v-btn text dark class="primary--text"> REPORTS </v-btn>
            <v-btn text dark class="primary--text"> BECOME A RESELLER </v-btn>
            <v-btn text dark class="primary--text"> CONTACT US </v-btn> -->
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn
              class="compeatbuttons"
              href="https://compeat.social/packages"
              target="_blank"
            >
              <span>Back to Packages</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <footer class="footercolor">
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="6" class="my-3">
            <v-img src="../src/assets/Compeat-Logo.png" width="350px" />
            <p class="mb-0">
              &copy; 2023 | Terms & Conditions | Privacy Policy | Resources For
              The Press | In The News | Articles
            </p>
          </v-col>
          <v-col cols="6" class="my-3 text-right">
            <v-row justify="center" align="center">
              <v-col cols="9" class="text-right">
                <wordchanger />
              </v-col>
              <v-col cols="3" class="text-right">
                <v-btn
                  class="compeatbuttons"
                  href="https://github.com/vuetifyjs/vuetify/releases/latest"
                  target="_blank"
                >
                  <span class="white--text">Get Compeat-ing</span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn icon class="black mx-1">
                  <v-icon color="white"> mdi-facebook </v-icon>
                </v-btn>
                <v-btn icon class="black mx-1">
                  <v-icon color="white"> mdi-instagram </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </v-app>
</template>

<script>
import wordchanger from "../src/components/footercomponents/wordchanger.vue";
export default {
  name: "App",
  components: {
    wordchanger,
  },
  data: () => ({
    //
  }),
};
</script>

<style></style>
