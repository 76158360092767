import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: [],
    facebookToken:'',
    loggedIntoFacebook:false
  },
  mutations: {
    setUser(state, userObj) {
      state.user = userObj;
      console.log(' state.user',  state.user)
    },
    setloggedIntoFacebook(state, userObj) {
      state.loggedIntoFacebook = userObj;
      console.log(' state.loggedIntoFacebook',  state.loggedIntoFacebook)
    },
    setUserToken(state, tokenObj) {
      state.facebookToken = tokenObj;
      console.log('state.facebookToken',  state.facebookToken)
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getLoggedInStatus(state) {
      return state.loggedIntoFacebook;
    },
    getUserFacebookToken(state) {
      return state.facebookToken;
    },
  },
  actions: {
  },
  modules: {
  }
})
