<template>
  <div>
    <p class="mb-0 compeattext">
      <b class="compeatbuttons--text">COMPEAT FOR</b> {{ currentWord }}
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({
    words: ["BUSINESS", "MARKETERS", "AGENCIES"],
    currentWordIndex: 0,
  }),
  computed: {
    currentWord() {
      return this.words[this.currentWordIndex % this.words.length];
    },
  },
  mounted() {
    // Update the word every 5 seconds
    setInterval(() => {
      this.currentWordIndex = (this.currentWordIndex + 1) % this.words.length;
    }, 3000);
  },
};
</script>

<style>
.compeattext {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 45px;
}
</style>
