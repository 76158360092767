import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
     theme:{
        themes:{
            light:{
                primary: '#3d6eb1',
                secondary: '#c92a81',
                compeatsecondary: '#c03b7d',
                compeatthird: '#65afbf',
                error: '#c10100',
                success: '#6aa84f',
                compeatbuttons: '#65b8e8',
                footercolor:'#f1f1f1'
                //  primary:'#eebf00'
            }
        }
    }
});
